/**
 * Filename:  _get-started.scss
 * Author:    Martin Dwyer
 * Purpose:   Provides styling for the Contact component
 * Date Mod:  December 8, 2021
 *
 */

@use "../custom"as *;
@use "../components/mixins"as *;

// Styling for the contact page


.get-started {
    padding: 15vh 0 4rem 0;


    div.container>div.row.text-white>div:nth-child(1)>div>h4 {
        color: $primary !important;
    }

    .display-3 {
        color: $white;
    }

    div>div>p {
        color: $white;
    }

    // Background and dimensions for the primary content
    div>div.row.text-white {
        padding-bottom: 3rem;
        background: linear-gradient(180deg,
                rgba($white, 0.3) 0%,
                rgba($white, 0.6) 35%,
                rgba($white, 0.8) 60%,
                rgba($white, 1) 100%);
        border-radius: 8px;

        .display-3--title {
            color: $white !important;
        }



        @media (max-width: 768px) {
            width: 95%;
            margin: 0 auto;
            background: linear-gradient(180deg,
                    rgba($white, 0.5) 0%,
                    rgba($white, 0.6) 35%,
                    rgba($white, 0.8) 60%,
                    rgba($white, 1) 100%);
        }
    }


    .cta-info {
        padding: 3rem;
        margin: 1rem 0;
        color: $primary;

        @media (max-width: 768px) {
            padding: 2em 1em;
        }

        h3 {
            font-weight: 700;
        }

        p.lh-lg {
            color: $primary;
            font-weight: 600;
        }

        &__list {
            position: relative;
            list-style-type: disc;
            display: block;
            padding-top: 0.5rem;
            padding-left: 0.5rem;
            margin-left: 2rem;
            margin-bottom: 0.7rem;

            li {
                font-size: 1.2rem;
                font-weight: 600;
                padding-left: 1rem;
                margin-bottom: 1rem;
            }
        }
    }

    .form {
        padding: 3rem;

        button {
            border: none;
            color: $white;

            a {
                color: $white;
                text-decoration: none;
                text-transform: capitalize;
            }
        }
    }

    @media (max-width: 768px) {
        div.form {
            padding: 2rem 1rem;
        }
    }

    #exampleModal {
        .modal-body {
            p {
                color: #000;
            }

            a {
                text-align: center;
                display: block;
                margin: 0 auto;
                color: $primary;
                font-weight: 700;
                text-decoration: none;

                &:hover {
                    color: blue;
                    text-decoration: underline;
                }
            }
        }

        modal-footer {
            button {
                color: #fff;
            }
        }
    }
}