@use "../custom"as *;
@use "../components/mixins"as *;
@use "../components/mixins";

#privacy-info {
    margin-top: 7rem;
    margin-bottom: 4rem;
    padding: 3rem;

    background-color: rgba(#fff, .9);
    border-radius: 8px;

    h3 {
        font-weight: 700;
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 2rem;
    }

    p {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    li {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-left: -1rem;
        padding-left: 1rem;
        margin-bottom: 1rem;
    }
}